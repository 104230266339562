.text-left{
    text-align: left;
}
.text-right{
    text-align: right;
}
.main-content{

}
#__react-alert__ div{
    pointer-events: initial !important;
}