.light-theme {
    --breakpoint-sm: 37.5rem;
    --breakpoint-md: 60rem;
    --max-width: var(--breakpoint-md);
    --space-1: 0.25rem;
    --space-2: 0.5rem;
    --space-3: 0.75rem;
    --space-4: 1rem;
    --color-background: #fff;
    --loading-background: "#f1f1f1";
    --color-border: rgba(0,0,0,.08);
    --color-text: #000;
    --border-radius-1: 5px;
    --border-radius-2: 10px;
    --border-radius-3: 15px;
    --color-shadow-01: rgba(0,0,0,.01);
    --color-shadow-02: rgba(0,0,0,.02);
    --color-shadow-03: rgba(0,0,0,.03);
    --color-shadow-04: rgba(0,0,0,.04);
    --color-shadow-05: rgba(0,0,0,.05);
    --color-shadow-06: rgba(0,0,0,.06);
    --color-shadow-07: rgba(0,0,0,.07);
    --color-shadow-08: rgba(0,0,0,.08);
    --color-shadow-09: rgba(0,0,0,.09);
    --color-shadow-1: rgba(0,0,0,.1);
    --color-shadow-2: rgba(0,0,0,.2);
    --color-shadow-3: rgba(0,0,0,.3);
    --color-shadow-4: rgba(0,0,0,.4);
    --color-shadow-5: rgba(0,0,0,.5);
    --color-shadow-6: rgba(0,0,0,.6);
    --color-shadow-7: rgba(0,0,0,.7);
    --color-shadow-8: rgba(0,0,0,.8);

    --color-background-alert: rgba(255,255,255, 0.9);
    --color-text-alert: rgba(0,0,0,.8);

    --form-element-background: #fff;
    --form-element-background-disabled: #fbfbfb;
    --form-element-text-color: rgba(0,0,0,.6);
    --form-element-text-color-2: rgba(0,0,0,.4);
    --form-element-text-color-disabled: rgba(0,0,0,.2);
    --form-element-border: #ced4da;
    --form-element-focus-shadow: rgb(0 0 0 / 3%);
    --form-element-dropdown-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");

    --color-table-header-background: rgba(0,0,0,.04);
    --color-table-header-text: rgba(0,0,0,.7);
    --color-table-header-border: rgba(0,0,0,0);
    --color-table-row-border: rgba(0,0,0,0);
    --color-table-row-odd-background: rgba(0,0,0,.05);
    --color-table-row-hover-background: rgba(0,0,0,.09);
    --color-table-pagination-button-background: #fff;
    --color-table-pagination-button-text: #333;
    --color-table-pagination-button-border: #333;
    --color-table-pagination-navigate-background: #333;
    --color-table-pagination-navigate-text: #fff;
    --color-table-pagination-navigate-border: #333;
    --color-table-pagination-button-disabled-background: #ccc;
    --color-table-pagination-button-disabled-text: #919191;
    --color-table-pagination-button-disabled-border: #ccc;

    --color-popup-overlay-background: rgba(255, 255, 255, .6);
    --color-popup-background: #fff;
    --color-popup-border: #e1e1e1;
    --color-popup-text: #333;

    --color-overview-table-value-background:  #919191;
    --color-overview-table-title-background: #e1e1e1;
    --color-overview-table-sign-background: #1c2b50;

    --color-header-background: #070505;
    --color-subheader-background: #ffffff;
    --color-header-text: #fff;
    --color-slider-background: #f1cc01;
    --color-secondary-on: #fff;
    --color-secondary-on2: #000;
    --color-primary: #990f32;
    --color-secondary: #b15582;
    --font-family: "Poppins", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    --font-size-1: 0.625rem;
    --font-size-2: 0.75rem;
    --z-index-1: 1010;
    --z-index-2: 1020;

    --bs-btn-color: #b20d5d;
    --bs-btn-border-color: #b20d5d;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #b20d5d;
    --bs-btn-hover-border-color: #b20d5d;
    --bs-btn-focus-shadow-rgb: 13,110,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #b20d5d;
    --bs-btn-active-border-color: #b20d5d;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #b20d5d;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #b20d5d;
}
.dark-theme {
    --breakpoint-sm: 37.5rem;
    --breakpoint-md: 60rem;
    --max-width: var(--breakpoint-md);
    --space-1: 0.25rem;
    --space-2: 0.5rem;
    --space-3: 0.75rem;
    --space-4: 1rem;
    --color-background: #000;
    --loading-background: "#333";
    --color-border: #262626;
    --color-text: #f2f2f2;
    --border-radius-1: 5px;
    --border-radius-2: 10px;
    --border-radius-3: 15px;
    --color-shadow-01: rgba(255,255,255,.01);
    --color-shadow-02: rgba(255,255,255,.02);
    --color-shadow-03: rgba(255,255,255,.03);
    --color-shadow-04: rgba(255,255,255,.04);
    --color-shadow-05: rgba(255,255,255,.05);
    --color-shadow-06: rgba(255,255,255,.06);
    --color-shadow-07: rgba(255,255,255,.07);
    --color-shadow-08: rgba(255,255,255,.08);
    --color-shadow-09: rgba(255,255,255,.09);
    --color-shadow-1: rgba(255,255,255,.1);
    --color-shadow-2: rgba(255,255,255,.2);
    --color-shadow-3: rgba(255,255,255,.3);
    --color-shadow-4: rgba(255,255,255,.4);
    --color-shadow-5: rgba(255,255,255,.5);
    --color-shadow-6: rgba(255,255,255,.6);
    --color-shadow-7: rgba(255,255,255,.7);
    --color-shadow-8: rgba(255,255,255,.8);

    --color-background-alert: rgba(255,255,255, 0.1);
    --color-text-alert: #fff;

    --form-element-background: #000;
    --form-element-background-disabled: rgba(255,255,255,.04);
    --form-element-text-color: rgba(255,255,255,.6);
    --form-element-text-color-2: rgba(255,255,255,.4);
    --form-element-text-color-disabled: rgba(255,255,255,.2);
    --form-element-border: #ced4da;
    --form-element-focus-shadow: rgb(255 255 255 / 3%);
    --form-element-dropdown-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");

    --color-table-header-background: rgba(255,255,255,.04);
    --color-table-header-text: rgba(255,255,255,.7);
    --color-table-header-border: rgba(255,255,255,0);
    --color-table-row-border: rgba(255,255,255,0);
    --color-table-row-odd-background: rgba(255,255,255,.05);
    --color-table-row-hover-background: rgba(255,255,255,.09);
    --color-table-row-hover-background: rgba(255,255,255,.1);
    --color-table-pagination-button-background: #fff;
    --color-table-pagination-button-text: #333;
    --color-table-pagination-button-border: #333;
    --color-table-pagination-navigate-background: #333;
    --color-table-pagination-navigate-text: #fff;
    --color-table-pagination-navigate-border: #333;
    --color-table-pagination-button-disabled-background: #ccc;
    --color-table-pagination-button-disabled-text: #919191;
    --color-table-pagination-button-disabled-border: #ccc;

    --color-popup-overlay-background: rgba(0, 0, 0, .6);
    --color-popup-background: #000;
    --color-popup-border: rgba(255,255,255,.2);
    --color-popup-text: #fff;

    --color-overview-table-value-background:  #919191;
    --color-overview-table-title-background: #e1e1e1;
    --color-overview-table-sign-background: #1c2b50;

    --color-header-background: #070505;
    --color-subheader-background: #ffffff;
    --color-header-text: #fff;
    --color-slider-background: #f1cc01;
    --color-secondary-on: #fff;
    --color-secondary-on2: #000;
    --color-primary: #990f32;
    --color-secondary: #b15582;
    --font-family: "Poppins", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    --font-size-1: 0.625rem;
    --font-size-2: 0.75rem;
    --z-index-1: 1010;
    --z-index-2: 1020;
}


.admin-theme {
    --breakpoint-sm: 37.5rem;
    --breakpoint-md: 60rem;
    --max-width: var(--breakpoint-md);
    --space-1: 0.25rem;
    --space-2: 0.5rem;
    --space-3: 0.75rem;
    --space-4: 1rem;
    --border-radius-1: 5px;
    --border-radius-2: 10px;
    --border-radius-3: 15px;

    --color-shadow-01: rgba(255,255,255,.01);
    --color-shadow-02: rgba(255,255,255,.02);
    --color-shadow-03: rgba(255,255,255,.03);
    --color-shadow-04: rgba(255,255,255,.04);
    --color-shadow-05: rgba(255,255,255,.05);
    --color-shadow-06: rgba(255,255,255,.06);
    --color-shadow-07: rgba(255,255,255,.07);
    --color-shadow-08: rgba(255,255,255,.08);
    --color-shadow-09: rgba(255,255,255,.09);
    --color-shadow-1: rgba(255,255,255,.1);
    --color-shadow-2: rgba(255,255,255,.2);
    --color-shadow-3: rgba(255,255,255,.3);
    --color-shadow-4: rgba(255,255,255,.4);
    --color-shadow-5: rgba(255,255,255,.5);
    --color-shadow-6: rgba(255,255,255,.6);
    --color-shadow-7: rgba(255,255,255,.7);
    --color-shadow-8: rgba(255,255,255,.8);

    --color-background-alert: rgba(255,255,255, 0.1);
    --color-text-alert: #fff;

    --form-element-background: #000;
    --form-element-background-disabled: #fbfbfb;
    --form-element-text-color: rgba(255,255,255,.6);
    --form-element-text-color-2: rgba(255,255,255,.4);
    --form-element-text-color-disabled: rgba(255,255,255,.2);
    --form-element-border: #ced4da;
    --form-element-focus-shadow: rgb(255 255 255 / 3%);
    --form-element-dropdown-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");

    --color-background: #0e131e;
    --color-border: #383838;
    --color-text: #fff;
    --color-sidebar-background: #141d34;
    --color-sidebar-border: #141d34;
    --color-sidebar-text: #fff;

    --color-headerbar-background: #172342;
    --color-headerbar-border: #172342;
    --color-headerbar-text: #fff;

    --color-card-background: #141d34;
    --color-card-text: #fff;

    --color-overview-table-value-background:  #172342;
    --color-overview-table-title-background: #141c2e;
    --color-overview-table-sign-background: #1c2b50;

    --color-table-header-background: #172342;
    --color-table-header-text: #fff;
    --color-table-header-border: #172342;
    --color-table-row-border: #172342;
    --color-table-row-odd-background: #070505;
    --color-table-row-hover-background: #141d34;
    --color-table-pagination-button-background: #172342;
    --color-table-pagination-button-text: #fff;
    --color-table-pagination-button-border: #172342;
    --color-table-pagination-navigate-background: #141d34;
    --color-table-pagination-navigate-text: #fff;
    --color-table-pagination-navigate-border: #141d34;
    --color-table-pagination-button-disabled-background: #121b32;
    --color-table-pagination-button-disabled-text: #545454;
    --color-table-pagination-button-disabled-border: #121b32;

    --color-popup-overlay-background: rgba(0, 0, 0, .6);
    --color-popup-background: #172342;
    --color-popup-border: #070505;
    --color-popup-text: #fff;

    --form-element-text-color: #fff;
    --form-element-text-color-2: #f1f1f1;
    --form-element-text-color-disabled: #7a7a7a;
    --color-ball-reserve-background: #f44336;
    --color-ball-reserve-text: #fff;

    --color-header-background: #070505;
    --color-subheader-background: #ffffff;
    --color-header-text: #fff;
    --color-slider-background: #f1cc01;
    --color-secondary-on: #fff;
    --color-secondary-on2: #000;
    --text-color: #fff;
    --text-color-on-primary: #333;
    --color-primary: #b20d5d;
    --color-secondary: #b15582;
    --color-board-number: #1dee19;
    --color-gray: rgba(0,0,0, 0.11);
    --font-family: "Poppins", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    --font-size-1: 0.625rem;
    --font-size-2: 0.75rem;
    --z-index-1: 1010;
    --z-index-2: 1020;
}

@keyframes loop {
    0% {
        transform: translateX(100%);
    }
    to {
        transform: translateX(-100%);
    }
}
@keyframes loop2 {
    0% {
        transform: translateX(0);
    }
    to {
        transform: translateX(-200%);
    }
}